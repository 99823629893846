import Helmet from "react-helmet";

import { ProgressIndicator } from "../../../components/Wizard/ProgressIndicator";
import { useAuth } from "../../../hooks/useAuth";
import { useWizard } from "../../../hooks/useWizard";
import { wizardSteps } from "../../../contexts/WizardContext"
import { Youtube } from "../../../components/Youtube";
import { Button } from "../../../components/Button";

import GoogleProductsImg from "../../../assets/img/content/google-products.png";
import TipsImg from "../../../assets/img/icons/tips.svg";
import { useState } from "react";
import { CreateCampaignLater } from "../../../components/SmartShoppingComponents/CreateCampaignLater";
import { Link } from "react-router-dom";
import { Tooltip } from "../../../components/Tooltip";

export default function SmartShoppingPage() {
  const auth = useAuth();
  const wizard = useWizard();
  const [isDoItLaterSidebarOpen, setIsDoItLaterSidebarOpen] = useState(false);

  return (
    <>
      <Helmet>
        <title>Smart Shopping - Traygle</title>
        <meta
          name="description"
          content="Vamos promover seus produtos da melhor forma, nos melhores lugares."
        />
        <meta property="og:title" content="Smart Shopping - Traygle" />
        <meta
          property="og:description"
          content="Vamos promover seus produtos da melhor forma, nos melhores lugares."
        />
      </Helmet>

      <section>
        <ProgressIndicator currentStep={5} />

        <div className="smart-shopping">
          <h1 className="smart-shopping__title">
            Tudo pronto! Vamos começar a promover seus produtos no Google.
          </h1>
          <img
            className="smart-shopping__img"
            src={GoogleProductsImg}
            alt="Verificado"
          />
          <p className="smart-shopping__text">
            A {auth.campaignTypeName} é uma forma de mostrar seus produtos para
            potenciais clientes por toda rede Google: <br />
            YouTube, Gmail e muito mais.
            <Tooltip className="ml-0.5" id="campaign" message="Seus produtos serão exibidos em toda rede Google que estiver disponível. Você controla seu investimento de acordo com sua campanha.">
              <img src={TipsImg} alt="Dicas" />
            </Tooltip>
          </p>
          <div className="smart-shopping__iframe">
            <Youtube
              videoId={'z66PDgmCClk'}
              height="315px"
              title="YouTube video player"
            />
          </div>

          {auth.googleAdsAccount?.isNew ? (
            <div style={{ flexDirection: 'column' }}>
              <Button
                className="button"
                style={{ fontSize: '1rem', lineHeight: '1.375rem', width: '100%' }}
                onClick={(e) => {
                  e.preventDefault();
                  wizard.updateCurrentStep(wizardSteps.SSC_CREATION)
                }}>
                Crie a sua campanha de maior desempenho agora
              </Button>
              <p className="widget-products__text" style={{ fontWeight: 'bold', marginTop: 10 }}>
                Gaste R$1.200 em até 60 dias e ganhe R$1.200 em crédito de anúncio.
                <br />
                <span style={{ fontSize: '0.75rem', fontWeight: 'normal' }}>
                  Válido para novas contas de Google Ads.{' '}
                  <a href="https://www.google.com/ads/coupons/terms/" target="_blank">Termos e condições</a>
                </span>
              </p>
            </div>
          ) : (
            <div className="smart-shopping__buttons">
              <Button
                data-open="modal-campaign"
                onClick={() => setIsDoItLaterSidebarOpen(true)}
              >
                Quero criar a campanha depois
              </Button>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  wizard.updateCurrentStep(wizardSteps.SSC_CREATION);
                }}>
                Criar {auth.campaignTypeName}
              </Link>
            </div>
          )}

          <CreateCampaignLater isOpen={isDoItLaterSidebarOpen} setIsOpen={setIsDoItLaterSidebarOpen} />
        </div>
      </section>
    </>
  );
}
