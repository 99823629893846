import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { AiOutlineSearch } from "react-icons/ai";

import { ReportItem } from "../../../components/ReportItem";
import { Pagination } from "../../../components/Pagination";
import { useAuth } from "../../../hooks/useAuth";
import { api } from "../../../services/api";

import SpinnerBlueSvgGif from "../../../assets/img/icons/spinner-blue.svg";

import styles from "./styles.module.scss";
import Navigation from "../../../components/Dashboard/Navigation";
import ProductsStats from "../../../components/Dashboard/ProductsStats";
import HelpLink from "../../../components/HelpLink";

export default function Reports() {
  const auth = useAuth();

  const [reports, setReports] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [reportType, setReportType] = useState("free_listings");
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(null);
  const [sort, setSort] = useState('clicks_desc');

  const [freeListingsPageTokens, setFreeListingsPageTokens] = useState([null, '']);

  useEffect(() => {
    loadData();
  }, [auth.store, perPage, currentPage, reportType, sort]);

  async function loadData(isNewSearch) {
    setLoading(true);

    let url = `google/reports/products/${reportType}`;

    if (
      auth.store === null ||
      !auth.store.google?.campaignId ||
      !auth.store.google?.googleAdsCustomerId
    ) {
      url = "google/reports/products/free_listings";
    }

    let params = {
      limit: perPage,
      dateRange: "LAST_7_DAYS",
      page: currentPage,
      sort
    };

    if (searchTerm && searchTerm !== '') {
      params['query'] = searchTerm;
    }

    if(isNewSearch) {
      params['page'] = 1;
      setCurrentPage(1);
    }

    if(reportType === 'free_listings') {
      params['pageToken'] = freeListingsPageTokens[currentPage];
    }

    try {
      const response = await api.get(url, { params });
      setReports(response.data.results);
      setLastPage(response.data?.lastPage);
      setTotalItems(response.data?.totalItems);

      if(response.data?.nextPageToken) {
        let tokens = JSON.parse(JSON.stringify(freeListingsPageTokens))
        tokens[parseInt(response.data.currentPage) + 1] = response.data?.nextPageToken;
        setFreeListingsPageTokens(tokens);
      }

    } catch {
      setFreeListingsPageTokens([null, '']);
      setReports([]);
      setLastPage(1);
      setTotalItems(0);
    } finally {
      setLoading(false);
    }
  }

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  return (
    <>
      <Helmet>
        <title>Relatórios dos Produtos - Traygle</title>
        <meta name="description" content="Relatórios dos Produtos - Traygle" />
        <meta property="og:title" content="Relatórios dos Produtos - Traygle" />
        <meta
          property="og:description"
          content="Relatórios dos Produtos - Traygle"
        />
      </Helmet>

      <main role="main">
        <section className="grid-container">
          <div className="reports">
            <Navigation currentSection="reports" />

            <section className="reports__visao--geral">
              <h1 className={styles.titleCampaign}>
                Relatório de produtos
                {auth.store?.google?.campaignId && (
                  <select
                    name="report_type"
                    id="report_type"
                    value={reportType}
                    onChange={(e) => {
                      setCurrentPage(1);
                      setReportType(e.target.value);
                    }}
                  >
                    <option value="free_listings">Listagem Grátis</option>
                    <option value="ads">{auth.campaignTypeName.replace('campanha', 'Campanha')}</option>
                  </select>
                )}
              </h1>

              <ProductsStats />
            </section>

            <section className="reports__products">
              <div className="header">
                <section>
                  <div className="input-control">
                    <input
                      type="text"
                      placeholder="Busque pelo título do produto"
                      value={searchTerm}
                      onChange={(event) => setSearchTerm(event.target.value)}
                    />

                    <button onClick={() => loadData(true)}>
                      <AiOutlineSearch className="mr-0.5" />
                      Buscar
                    </button>
                  </div>

                  {/* <div className="divider"></div>

                  <button className="filter-button" type="button">
                    <FaFilter className="mr-0.5" />
                    Filtros
                  </button> */}
                </section>

                <select
                  name="sort"
                  value={sort}
                  onChange={(e) => {
                    setSort(sort);
                    setSort(e.target.value);
                  }}
                >
                  <option value="clicks_desc">Mais cliques</option>
                  <option value="clicks_asc">Menos cliques</option>
                  <option value="impressions_desc">Mais impressões</option>
                  <option value="impressions_asc">Menos impressões</option>

                  {reportType === 'ads' &&
                    <>
                      <option value="conversions_desc">Mais conversões</option>
                      <option value="conversions_asc">Menos conversões</option>
                      <option value="cost_micros_desc">Custo</option>
                      <option value="all_conversions_value_desc">Total vendido</option>
                    </>
                  }
                </select>
              </div>

              <main>
                {loading ? (
                  <div className="text-align-center">
                    <img src={SpinnerBlueSvgGif} alt="Animation spinner" />
                  </div>
                ) : (
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th>Produtos</th>
                          <th>Impressões</th>
                          <th>Cliques</th>

                          {reportType === 'ads' &&
                            <>
                              <th>Conversões</th>
                              <th>Custo</th>
                              <th>Total vendido</th>
                              <th>ROAS</th>
                            </>
                          }

                          <th>Status</th>
                          <th className="text-align-center">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reports.map((report) => (
                          <ReportItem 
                            key={report.id} 
                            report={report} 
                            reportType={reportType} 
                            freeListingsImage={report.imageUrl}
                          />
                        ))}
                      </tbody>
                    </table>

                    {totalItems === 0 &&
                      <div className="text-align-center">
                        <p>Não existem produtos a serem exibidos.</p>
                      </div>
                    }
                  </>
                )}

                <section className="pagination">
                  <div className="select-container">
                    <p>Exibir</p>
                    <select
                      name="limit"
                      value={perPage}
                      onChange={(event) => {
                        setCurrentPage(1);
                        setPerPage(Number(event.target.value));
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>

                    <p className="total-itens">
                      {currentPage * perPage - perPage + 1} a{" "}
                      {(currentPage * perPage) > totalItems ? totalItems : (currentPage * perPage)}{" "}
                      de {totalItems}
                    </p>
                  </div>

                  <Pagination
                    hasNextPage={currentPage < lastPage}
                    currentPage={currentPage}
                    lastPage={lastPage}
                    totalItems={totalItems}
                    onPageChange={handlePageChange}
                    showFirstAndLastPageArrows={reportType === 'ads'}
                  />
                </section>
              </main>
            </section>

            <HelpLink />
          </div>
        </section>
      </main>
    </>
  );
}
