import { useState } from "react";
import Helmet from "react-helmet";

import { ProgressIndicator } from "../../../components/Wizard/ProgressIndicator";
import { Button } from "../../../components/Button";
import { useWizard } from "../../../hooks/useWizard";
import { wizardSteps } from "../../../contexts/WizardContext"

import TipsImg from "../../../assets/img/icons/tips.svg";
import { Tooltip } from "../../../components/Tooltip";

export default function ResumoContasPage() {
  const wizard = useWizard();

  const [certificateSSL, setCertificateSSL] = useState(true);
  const [termsAndConditions, setTermsAndConditions] = useState(true);

  return (
    <>
      <Helmet>
        <title>Resumo das Contas - Traygle</title>
        <meta
          name="description"
          content="O Google Ads tem como requisito a instalação de tags que irão rastrear as vendas do seu site e também criar listas de remarketing."
        />
        <meta property="og:title" content="Resumo das Contas  - Traygle" />
        <meta
          property="og:description"
          content="O Google Ads tem como requisito a instalação de tags que irão rastrear as vendas do seu site e também criar listas de remarketing."
        />
      </Helmet>

      <div className="all-accounts">
        <ProgressIndicator currentStep={2} />

        <div className="resume">
          <h2 className="resume__title">Quase tudo pronto!</h2>

          <div className="resume__itens--container">
            <div className="resume__confirm">
              <p>
                Declaro estar ciente dos seguintes requisitos
                do Google Merchant Center
              </p>
              <div className="checkbox-group">
                <label className="control control--checkbox">
                  <input
                    className="checkbox-group__input"
                    checked
                    disabled
                    type="checkbox"
                    id="ssl"
                    name="ssl"
                    value={certificateSSL}
                    onChange={(event) =>
                      setCertificateSSL(event.target.checked)
                    }
                  />
                  <div className="control__indicator"></div>
                  <label htmlFor="ssl">Certificado SSL</label>
                </label>
                <Tooltip id="ssl_cert" message="Certificado digital que autentica seu site e possibilita uma conexão segura criptografada dos dados">
                  <img src={TipsImg} alt="Dicas" />
                </Tooltip>
              </div>

              <div className="checkbox-group">
                <label className="control control--checkbox">
                  <input
                    className="checkbox-group__input"
                    checked
                    disabled
                    type="checkbox"
                    id="price"
                    name="price"
                    value={termsAndConditions}
                    onChange={(event) =>
                      setTermsAndConditions(event.target.checked)
                    }
                  />
                  <div className="control__indicator"></div>
                  <label htmlFor="price">Termos e condições de preços</label>
                </label>
                <Tooltip id="price_terms" message="Utilizar os preços dos produtos de acordo com os termos do Google Merchant Center">
                  <img src={TipsImg} alt="Dicas" />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="resume__buttons account__form__item account__form__item--buttons">
            <Button onClick={() => wizard.updateCurrentStep(wizardSteps.PHONE_VERIFICATION)}>Voltar</Button>
            <Button
              disabled={
                !certificateSSL ||
                !termsAndConditions
              }
              onClick={() => wizard.updateCurrentStep(wizardSteps.GOOGLE_ADS_CONNECTION)}
              submitingMessage="Conectando contas"
            >
              Confirmar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
