import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navigation from "../../../components/Dashboard/Navigation";
import MerchantCenterProblems from "../../../components/Dashboard/MerchantCenterProblems";
import ProductProblems from "../../../components/Dashboard/ProductProblems";
import GroupProductProblems from "../../../components/Dashboard/GroupProductProblems";
import HelpLink from "../../../components/HelpLink";
import { useAuth } from "../../../hooks/useAuth";
import { ReviewRequest } from "../../../components/ReviewRequest";
import { useLocation } from "react-router-dom";

function Problems() {
  const auth = useAuth();
  const { search } = useLocation();
  const [selectedTab, setSelectedTab] = useState("products");

  useEffect(() => {
    if (search.includes("tab=account")) {
      setSelectedTab("account");
    } else {
      setSelectedTab("products");
    }
  }, [search]);

  return (
    <>
      <Helmet>
        <title>Problemas - Traygle</title>
        <meta name="description" content="Problemas - Traygle" />
        <meta property="og:title" content="Problemas - Traygle" />
        <meta property="og:description" content="Problemas - Traygle" />
      </Helmet>

      <main role="main">
        <section className="grid-container">
          <div class="problems">
            <Navigation currentSection="problems" />

            <h1>Problemas</h1>

            <section class="problems__visao--geral">
              <ul className="dashboard__nav">
                <li className={selectedTab === "products" ? "active" : ""}>
                  <a onClick={() => setSelectedTab("products")}>
                    Problemas de produto
                    <span>{auth.issues?.productLevelIssues?.itemLevelIssues?.length ? auth.issues?.productLevelIssues?.itemLevelIssues?.length : 0}</span>
                  </a>
                </li>
                <li className={selectedTab === "account" ? "active" : ""}>
                  <a onClick={() => setSelectedTab("account")}>
                    Problemas de conta
                    <span>{auth?.issues?.accountLevelIssues ? auth?.issues?.accountLevelIssues.length : 0}</span>
                  </a>
                </li>
              </ul>

              {/* We are setting the display style to hide or show in this component to avoid consecutive
              component rerenders and unecessary API calls */}
              <div style={{ display: selectedTab === "products" ? "" : "none" }}>
                {/* <ProductProblems productProblemsProps={auth.issues?.productLevelIssues} /> */}
                <GroupProductProblems productProblemsProps={auth.issues?.productLevelIssues || []} />
              </div>

              {selectedTab === "account" && <MerchantCenterProblems merchantProblems={auth.issues?.accountLevelIssues} />}
            </section>

            {selectedTab === "account" && <ReviewRequest />}

            <HelpLink />
          </div>
        </section>
      </main>
    </>
  );
}

export default Problems;
