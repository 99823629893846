/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import FiltersSection from "../../../components/EditCampaign/FiltersSection";
import GeneralSection from "../../../components/EditCampaign/GeneralSection";
import InvestmentSection from "../../../components/EditCampaign/InvestmentSection";
import RemoveSection from "../../../components/EditCampaign/RemoveSection";
import StatusSection from "../../../components/EditCampaign/StatusSections";

import HelpLink from "../../../components/HelpLink";

import { api } from "../../../services/api";
import { useAuth } from "../../../hooks/useAuth";
import { Button } from "../../../components/Button";
import SpinnerBlueSvgGif from "../../../assets/img/icons/spinner-blue.svg";
import LogsModal from "../../../components/LogsModal";
import Navigation from "../../../components/Dashboard/Navigation";

function EditCampaign() {
    const auth = useAuth();
    const history = useHistory();

    const [isLoadingCampaign, setIsLoadingCampaign] = useState(true);
    const [isSavingCampaign, setIsSavingCampaign] = useState(false);

    const [campaignData, setCampaignData] = useState({
        name: "",
        dailyAmountInCents: 0,
        filter: { operation: 'default' },
        status: "ENABLED",
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (
            auth.store === null ||
            !auth.store.google?.campaignId ||
            !auth.store.google?.googleAdsCustomerId
        )
            return;

        loadCampaignData();
    }, [auth.store]);

    async function loadCampaignData() {
        try {
            setIsLoadingCampaign(true);

            const campaignId = auth.store.google.campaignId;
            const customerId = auth.store.google.googleAdsCustomerId;

            await api
                .get(`/google/ads/customers/${customerId}/campaigns/${campaignId}`)
                .then((res) => {
                    setCampaignData(res.data);
                });
        } catch {
        } finally {
            setIsLoadingCampaign(false);
        }
    }

    function changeCampaignData(values) {
        setCampaignData((old) => ({ ...old, ...values }));
    }

    async function saveCampaign() {
        setIsSavingCampaign(true);

        if (
            auth.store === null ||
            !auth.store.google?.campaignId ||
            !auth.store.google?.googleAdsCustomerId
        ) {
            throw Error("Invalid data.");
        }
        const campaignId = auth.store.google.campaignId;
        const customerId = auth.store.google.googleAdsCustomerId;

        const data = {
            name: campaignData.name,
            dailyAmountInCents: campaignData.dailyAmountInCents
        };

        try {
            await api.put(
                `/google/ads/customers/${customerId}/campaigns/${campaignId}`,
                data
            );

            await api.post(
                `/google/ads/customers/${customerId}/campaigns/${campaignId}/filter`,
                campaignData.filter
            );

            if (hasDailyAmountChanged()) {
                localStorage.clear();
            }

            toast.success(`Campanha criada com sucesso.`);
        } catch {
        } finally {
            setIsSavingCampaign(false);
        }
    }

    function hasDailyAmountChanged() {
        const previousAmount = localStorage.getItem('previousDailyAmount');
        const currentAmount = campaignData.dailyAmountInCents;
    
        if (previousAmount && parseInt(previousAmount, 10) !== currentAmount) {
            return true;
        }
    
        localStorage.setItem('previousDailyAmount', currentAmount);
        return false;
    }

    return (
        <>
            <Helmet>
                <title>Configurações - Traygle</title>
                <meta name="description" content="Configurações - Traygle" />
                <meta property="og:title" content="Configurações - Traygle" />
                <meta property="og:description" content="Configurações - Traygle" />
            </Helmet>

            <LogsModal
                handleClose={() => setIsModalOpen(!isModalOpen)}
                isOpen={isModalOpen}
                scope='campaign'
                scopeId={auth.store.google.campaignId}
                campaign={campaignData}
            />

            <main role="main">
                <section className="grid-container">
                    <div className="edit-campaign">
                        <Navigation currentSection="campaign" />

                        <section>
                            <div class="edit-campaign__title">
                                <h1>Editar Campanha</h1>
                                <button onClick={() => setIsModalOpen(true)} class="edit-campaign__button changes">Registro de alterações</button>
                            </div>

                            {isLoadingCampaign ? (
                                <div className="text-align-center" style={{ display: 'block' }}>
                                    <img src={SpinnerBlueSvgGif} alt="Animation spinner" />
                                </div>
                            ) : (
                                <>
                                    <main class="grid-x">

                                        <section class="cell small-12 large-6">
                                            <StatusSection campaign={campaignData} changeCampaignData={changeCampaignData} />
                                            <GeneralSection campaign={campaignData} changeCampaignData={changeCampaignData} />
                                            <InvestmentSection campaign={campaignData} changeCampaignData={changeCampaignData} />
                                        </section>

                                        <section class="cell small-12 large-6">
                                            <FiltersSection campaign={campaignData} changeCampaignData={changeCampaignData} />
                                            <RemoveSection />
                                        </section>
                                    </main>

                                    <div className="grid-x help-saveSection">
                                        <div className="cell small-12 large-10">
                                            <HelpLink />
                                        </div>

                                        <section className="cell small-12 large-2 saveSection">
                                            <button onClick={() => history.push('/settings')}>Cancelar</button>
                                            <Button
                                                disabled={campaignData.name === ""}
                                                isSubmitting={isSavingCampaign}
                                                submitingMessage=""
                                                onClick={saveCampaign}
                                            >
                                                Salvar
                                            </Button>
                                        </section>
                                    </div>
                                </>
                            )}
                        </section>
                    </div>
                </section>
            </main>
        </>
    );
}

export default EditCampaign;
